import i18next from 'i18next';
import Alpine from '@alpinejs/csp'
import { closeModal } from './utils.js'

window.Alpine = Alpine;
Alpine.start();


document.addEventListener('DOMContentLoaded', function () {
    // Initialize i18next with the default or previously selected language
    const defaultLang = localStorage.getItem('selectedLanguage') || 'fi';
    changeLanguage(defaultLang);

    // Listen for changes on the language switcher
    document.getElementById('languageSwitcher').addEventListener('change', function (e) {
        const newLang = e.target.value;
        changeLanguage(newLang);
    });

    function changeLanguage(lang) {
        fetch(`/static/locales/${lang}/translation.json`)
            .then(response => response.json())
            .then(translation => {
                if (!i18next.isInitialized) {
                    // Initialize i18next for the first time
                    i18next.init({
                        lng: lang,
                        resources: {
                            [lang]: {
                                translation
                            }
                        },
                        fallbackLng: 'fi',
                        debug: true
                    }, function (err, t) {
                        afterLanguageChange(lang);
                    });
                } else {
                    // Update the translation resources for the new language
                    i18next.addResourceBundle(lang, 'translation', translation);
                    // Just change the language since i18next is already initialized
                    i18next.changeLanguage(lang, function (err, t) {
                        afterLanguageChange(lang);
                    });
                }
            }).catch(error => console.error('Error loading the translation files:', error));
    }
    
    function afterLanguageChange(lang) {
        // Update content translations
        updateContent();
        setPlaceholders();
        // Save the selected language for persistence across page reloads
        localStorage.setItem('selectedLanguage', lang);
    
        // Toggle visibility of elements based on the language
        document.querySelectorAll('.content').forEach(el => {
            if (el.getAttribute('lang') === lang) {
                el.classList.remove('hidden');
            } else {
                el.classList.add('hidden');
            }
        });
    }

    function updateContent() {
        document.querySelectorAll('[data-i18n]').forEach(element => {
            const translationKey = element.getAttribute('data-i18n');
            element.innerHTML = i18next.t(translationKey);
        });
    }
});


document.body.addEventListener('htmx:afterRequest', function(evt) {
    console.log(evt)
    if (evt.detail.response?.redirect) {
        window.location = evt.detail.response.redirect;
    }
});

 
document.body.addEventListener('htmx:afterRequest', function(event) {
    const xhr = event.detail.xhr

    if (xhr.responseText &&(xhr.status === 200 || xhr.status === 201 || xhr.status === 204)) {
        const notification = xhr.responseText;
        let notificationModal = document.getElementById('notificationModal')

        if (notification === "BASIC_THANK_YOU") {
            const localizedErrorMessge = i18next.t(notification)
            document.getElementById('notificationText').textContent = localizedErrorMessge
            notificationModal.showModal()

            setTimeout(() => {
                closeModal('notificationModal')
            }, 3000);
        }   
    }
});


function updateContentTranslations() {
    const currentLang = localStorage.getItem('selectedLanguage') || 'fi';

    document.querySelectorAll('#main-content [data-i18n]').forEach(element => {
        const translationKey = element.getAttribute('data-i18n');
        element.innerHTML = i18next.t(translationKey);
    });

    document.querySelectorAll('#main-content .content').forEach(el => {
        if (el.getAttribute('lang') === currentLang) {
            el.classList.remove('hidden');
        } else {
            el.classList.add('hidden');
        }
    });
}

document.body.addEventListener('htmx:afterSwap', function (event) {
    updateContentTranslations();
    setPlaceholders();
});

function setPlaceholders() {
    document.querySelectorAll('input[placeholder]').forEach(function (input) {
        input.placeholder = i18next.t('search_placeholder');
    });
}


document.body.addEventListener('htmx:responseError', function (event) {
    if (event.detail.xhr.status === 400 || event.detail.xhr.status === 500) {
        let errorModal = document.getElementById('errorModal')
        const errorCode = event.detail.xhr.responseText
        
        if (errorCode) {
            const localizedErrorMessge = i18next.t(errorCode)
            document.getElementById('errorText').textContent = localizedErrorMessge
            errorModal.showModal()

            setTimeout(() => {
                closeModal('errorModal')
            }, 3000);
        }
    }
});