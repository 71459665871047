export function closeModal(modalId)  {
    let modal = document.getElementById(modalId)
    
    if (modal) {
        modal.close()
    }
}

export function openModal(elementId) {
    let modal = document.getElementById(elementId)
    
    if (modal) {    
        modal.showModal()
    }
}


export function toggleClassesVisibility(visibleId, hiddenId) {
    let visibleClass = document.getElementById(visibleId);
    let hiddenClass = document.getElementById(hiddenId);

    if (visibleClass && hiddenClass) {
        visibleClass.classList.remove('hidden');
        hiddenClass.classList.add('hidden');
    }
}


export function debounce(func, delay) {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
}

export function addHrefStyle() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function(e) {
            // Prevent the default action for anchor tags
            e.preventDefault();

            const targetId = this.getAttribute('href');
            // Check if targetId is valid (not just '#' and not empty)
            if (targetId && targetId !== '#') {
                const targetElement = document.querySelector(targetId);
                if (targetElement) {
                    // If the target element exists, scroll to it smoothly
                    targetElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }
            }
        });
    });
}
